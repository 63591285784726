<template>
	<div ref="wrapper" class="agenda-calendar-wrapper" :class="{ 'is-overflow': overflow }">
		<div ref="scrollheader" class="agenda-calendar-scroll" :class="{ 'sticky-headers': fixedHeaders }">
			<div class="agenda-calendar-headers" :style="calendarStyle">
				<div class="agenda-calendar__time agenda-calendar__live-btn-wrapper">
					<button v-if="isLiveDay" class="agenda-calendar__live-btn" @click.prevent="goToLive">Now</button>
				</div>
				<div v-for="(track, index) in tracks" :ref="'trackTitle'" class="agenda-calendar__track-title">
					<i>{{ track.order }}</i>
					<strong>{{ track.title }}</strong>
				</div>
				<div v-if="hasExternals" class="agenda-calendar__track-title" :style="{ flexBasis: `${externalMultiple * 200}px` }">
					<strong>External sessions</strong>
				</div>
			</div>
		</div>
		<div ref="scroll" class="agenda-calendar-scroll" :class="{ 'is-overflow': overflow }">
			<div ref="calendar" :key="refresh" class="agenda-calendar" :style="calendarStyle">
				<div class="agenda-calendar__time">
					<ul>
						<li v-for="hour in listTime">{{ hour | calendarTimeFormat }}</li>
					</ul>
				</div>
				<div v-for="track in tracks" class="agenda-calendar__track">
					<div class="agenda-calendar__session-wrapper">
						<div v-for="session in trackSessions(track.id)" :key="session.id" class="agenda-calendar__session" :style="computeSessionStyle(session)" @click="openSession(session)">
							<AgendaSessionBox :session="session" :view="'small'" :alt-title="altTitle" :starred="starred" />
						</div>
					</div>
				</div>
				<div v-if="hasExternals" class="agenda-calendar__track" :style="{ flexBasis: `${externalMultiple * 200}px` }">
					<div class="agenda-calendar__session-wrapper">
						<div
							v-for="session in externalSessions"
							:key="session.id"
							class="agenda-calendar__session"
							:style="computeSessionStyleAlt(session, externalSessions)"
							@click="openSession(session)"
						>
							<AgendaSessionBox :session="session" :view="'small'" :alt-title="altTitle" :starred="starred" />
						</div>
					</div>
				</div>
				<span v-for="hour in listTime" class="hour-line" :style="computeHourLine(hour)"></span>
				<div v-if="isLiveDay" ref="liveLine" class="agenda-calendar__live-line" :style="liveLineStyle"></div>
			</div>
		</div>
		<div class="agenda-calendar-overflow">
			<div v-show="scrollLeftVisible" class="left" :style="overflowStyle" @click="scrollLeft">
				<inline-svg :src="require('../assets/arrow2.svg')" width="32"></inline-svg>
			</div>
			<div v-show="scrollRightVisible" class="right" :style="overflowStyle" @click="scrollRight">
				<inline-svg :src="require('../assets/arrow2.svg')" width="32"></inline-svg>
			</div>
		</div>
	</div>
</template>

<script>
	import AgendaSessionModal from './AgendaSessionModal';
	import AgendaSessionBox from './AgendaSessionBox';
	import moment from 'moment-timezone';

	const HOUR_PX = 140;

	export default {
		name: 'AgendaCalendar',
		components: { AgendaSessionBox },
		props: ['sessions', 'currentDate', 'altTitle', 'starred'],
		data() {
			return {
				date: new Date(),
				fixedHeaders: false,
				overflowStyle: { top: '0' },
				overflow: false,
				calendarStyle: {},
				scrollLeftVisible: false,
				scrollRightVisible: false,
				refreshInterval: null,
				liveLineStyle: {},
				refresh: 0,
				externalMultiple: 1,
			};
		},
		computed: {
			tracks() {
				let tracks = [];
				for (let session of this.sessions) {
					if (session.live_track) {
						if (!tracks.find(e => e.id === session.live_track.id)) {
							tracks.push({ ...session.live_track });
						}
					}
				}
				return tracks.sort((x, y) => x.order - y.order);
			},
			visibleRange() {
				let start = new Date(this.date);
				start.setHours(23);
				let end = new Date(this.date);
				end.setHours(0);

				let day = new Date(this.date).toISOString().slice(0, 10);

				for (let session of this.sessions) {
					if (session.live_track || session.external) {
						let sessionStart = moment(day + ' ' + session.start).toDate();
						if (sessionStart < start) {
							start = sessionStart;
						}
						let sessionEnd = moment(day + ' ' + session.end).toDate();
						if (sessionEnd > end) {
							end = sessionEnd;
						}
					}
				}

				start.setMinutes(0);

				return { start, end };
			},
			listTime() {
				let list = [];
				let start = new Date(this.visibleRange.start);
				let end = new Date(this.visibleRange.end);

				while (start < end) {
					let value = new Date(start);
					list.push(value.toISOString());
					start.setMinutes(start.getMinutes() + 30);
				}
				return list;
			},
			isLiveDay() {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${this.currentDate} 00:00:00`, 'CET');
				let dEnd = moment.tz(`${this.currentDate} 23:59:59`, 'CET');
				let now = moment().tz(target);

				return now.isBetween(dStart, dEnd);
			},
			externalSessions() {
				return this.sessions.filter(e => e.external === true);
			},
			hasExternals() {
				return this.externalSessions.length > 0;
			},
		},
		watch: {
			sessions: {
				handler: 'tickResize',
				immediate: true,
			},
		},
		mounted() {
			this.onResize();
			this.onScroll();
			window.addEventListener('resize', this.onResize);
			window.addEventListener('scroll', this.onScroll);
			this.refreshLiveProgress();
			this.refreshInterval = window.setInterval(() => {
				this.refreshLiveProgress();
			}, 30 * 1000);
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.onResize);
			window.removeEventListener('scroll', this.onScroll);
			if (this.refreshInterval) {
				window.clearInterval(this.refreshInterval);
			}
		},
		methods: {
			trackSessions(trackId) {
				return this.sessions.filter(e => e.live_track && e.live_track.id === trackId);
			},
			computeSessionStyle(session) {
				let sessionStart = moment(session.date + ' ' + session.start);
				let sh = sessionStart.hour();
				let sm = sessionStart.minute();

				let sessionEnd = moment(session.date + ' ' + session.end);
				let eh = sessionEnd.hour();
				let em = sessionEnd.minute();

				let diffh = (eh - sh) * 60 + (em - sm);

				let dayStart = this.visibleRange.start.getHours();

				let height = (diffh / 30) * HOUR_PX;
				let top = ((sh * 60 + sm - dayStart * 60) / 30) * HOUR_PX;

				return { height: height / 16 + 'rem', top: top / 16 + 'rem' };
			},
			computeSessionStyleAlt(session, sessions) {
				let multipleCheck = sessions.filter(e => e.date === session.date && e.start === session.start);
				let hasMulti = false;
				let multiCount = 0;
				if (multipleCheck.length > 1) {
					hasMulti = true;
					multiCount = multipleCheck.length;
					if (this.externalMultiple < multiCount) {
						this.externalMultiple = multiCount;
					}
				}
				let multiPos = multipleCheck.indexOf(session);

				let sessionStart = moment(session.date + ' ' + session.start);
				let sh = sessionStart.hour();
				let sm = sessionStart.minute();

				let sessionEnd = moment(session.date + ' ' + session.end);
				let eh = sessionEnd.hour();
				let em = sessionEnd.minute();

				let diffh = (eh - sh) * 60 + (em - sm);

				let dayStart = this.visibleRange.start.getHours();

				let height = (diffh / 30) * HOUR_PX;
				let top = ((sh * 60 + sm - dayStart * 60) / 30) * HOUR_PX;

				return {
					height: height / 16 + 'rem',
					top: top / 16 + 'rem',
					width: hasMulti ? `${100 / multiCount}%` : '100%',
					left: hasMulti && multiPos > 0 ? `${(100 / multiCount) * multiPos}%` : 0,
				};
			},
			computeHourLine(hour) {
				let date = moment(hour);
				let a = date.hour();
				let b = date.minute();

				let dayStart = this.visibleRange.start.getHours();

				let top = ((a * 60 + b - dayStart * 60) / 30) * HOUR_PX;

				return { top: top / 16 + 'rem' };
			},
			computeLiveLine() {
				let now = moment().tz('Europe/Lisbon');
				let a = now.hour();
				let b = now.minute();

				if (now.toDate() < this.visibleRange.end) {
					let dayStart = this.visibleRange.start.getHours();

					let top = ((a * 60 + b - dayStart * 60) / 30) * HOUR_PX;

					this.liveLineStyle = { top: top / 16 + 'rem' };
				} else {
					this.liveLineStyle = { display: 'none' };
				}
			},
			refreshLiveProgress() {
				this.computeLiveLine();
				this.refresh = this.refresh + 1;
			},
			openSession(session) {
				if (session.visitable) {
					this.$gtm.dataLayer().push({
						event: 'gaEvent',
						eCategory: 'Programme Agenda Event',
						eAction: session.session_category ? session.session_category.title + ' - Calendar' : 'Calendar',
						eLabel: session.title,
						Exhibitor: session.session_ga ? session.session_ga : '(not set)',
						Booth: '(not set)',
					});
					this.$modal.show(
						AgendaSessionModal,
						{
							session,
							altTitle: this.altTitle,
							starred: this.starred,
						},
						{
							classes: 'agenda-session-modal',
							width: '90%',
							maxWidth: 900,
							height: 'auto',
							adaptive: true,
						}
					);
				}
			},
			tickResize() {
				this.$nextTick(() => {
					this.externalMultiple = 1;

					this.computeLiveLine();
					this.$nextTick(() => {
						this.onResize();
					});
				});
			},
			onResize() {
				this.overflow = this.$refs.calendar.scrollWidth > this.$refs.scroll.offsetWidth;
				console.log('on resize', this.overflow);
				if (this.overflow === true) {
					let width = (85 + 280 * this.tracks.length) / 16;
					this.calendarStyle.width = width + 'rem';
					this.scrollRightVisible = this.$refs.scroll.scrollLeft + this.$refs.scroll.offsetWidth <= this.$refs.calendar.scrollWidth;
					this.scrollLeftVisible = this.$refs.scroll.scrollLeft > 0;
				} else {
					this.calendarStyle = {};
				}
			},
			onScroll(event) {
				let top = this.$refs.calendar.getBoundingClientRect().top;
				this.fixedHeaders = top < 0;
				if (top < 0) {
					this.overflowStyle.top = top * -1 + 200 + 'px';
				} else {
					this.overflowStyle.top = '200px';
				}
			},
			scrollLeft() {
				let newScroll = this.$refs.scroll.scrollLeft - this.$refs.trackTitle[0].offsetWidth;
				if (!this.scrollRightVisible) newScroll = newScroll + 40;
				this.$refs.scroll.scrollLeft = newScroll;
				this.$refs.scrollheader.scrollLeft = newScroll;
				this.scrollRightVisible = newScroll + this.$refs.scroll.offsetWidth <= this.$refs.calendar.scrollWidth;
				this.scrollLeftVisible = newScroll > 0;
			},
			scrollRight() {
				let newScroll = this.$refs.scroll.scrollLeft + this.$refs.trackTitle[0].offsetWidth;
				this.$refs.scroll.scrollLeft = newScroll;
				this.$refs.scrollheader.scrollLeft = newScroll;
				this.scrollRightVisible = newScroll + this.$refs.scroll.offsetWidth <= this.$refs.calendar.scrollWidth;
				this.scrollLeftVisible = newScroll > 0;
			},
			goToLive() {
				this.$refs.liveLine.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				});
			},
		},
	};
</script>
