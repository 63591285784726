<template>
	<div>
		<div class="agenda__days" :class="{ 'no-filter': !showFilter }">
			<ul>
				<li v-for="(date, index) in dates" :key="date">
					<a :class="{ 'is-active': date === currentDate }" @click.prevent="selectDate(date)">
						<strong v-if="dates.length > 1">Day {{ index + 1 }}</strong>
						<span>{{ date | dayFormat }}</span>
					</a>
				</li>
			</ul>
		</div>
		<div v-if="showCategories" class="agenda__categories">
			<ul>
				<li v-for="category in categories" :key="category.id">
					<a :class="{ 'is-active': selectedCategory === category.id }" @click.prevent="selectCategory(category.id)">
						<span>{{ category.title }}</span>
					</a>
				</li>
			</ul>
			<div class="checkbox-wrapper">
				<input id="mySessions" v-model="filterFavourites" type="checkbox" />
				<label class="checkbox" for="mySessions">
					Show only sessions in My Agenda
				</label>
			</div>
		</div>
		<div v-if="showFilter" class="agenda__controls">
			<div class="agenda__search field">
				<div class="agenda__search__input control has-icons-left">
					<span class="icon is-left">
						<inline-svg :src="require('../assets/search.svg')" width="32"></inline-svg>
					</span>
					<input v-model="debouncedSearch" class="input" type="text" placeholder="Search Session" />
				</div>
				<div class="agenda__search__type control">
					<v-select v-model="filterType" multiple :options="types" label="name"></v-select>
				</div>
				<div v-click-outside="closeSearchFilters">
					<button class="agenda__search__button" :class="{ 'is-active': searchFilterActive }" @click.prevent="toggleMore">
						<inline-svg :src="require('../assets/filter.svg')" width="28"></inline-svg>
						<span v-if="searchFilterActive && searchFilterCount" class="filter-counter">{{ searchFilterCount }}</span>
					</button>
					<div class="agenda__search__more" :class="{ 'is-open': searchMore }">
						<div class="columns is-multiline">
							<div class="column is-half">
								<div class="field">
									<label class="label">Speaker</label>
									<div class="control">
										<v-select v-model="filterSpeaker" multiple :options="speakers" label="name"></v-select>
									</div>
								</div>
							</div>
							<div class="column is-half">
								<div class="field">
									<label class="label">Channel</label>
									<div class="control">
										<v-select v-model="filterChannel" multiple :options="channels" label="title"></v-select>
									</div>
								</div>
							</div>
							<div class="column is-half">
								<div class="field">
									<label class="label">Hall</label>
									<div class="control">
										<v-select v-model="filterHall" multiple :options="halls" label="name"></v-select>
									</div>
								</div>
							</div>
							<div class="column has-text-right agenda__search__buttons">
								<a class="button is-primary is-outlined space-right" @click="clearFilters">Clear All</a>
								<a class="button is-primary" @click="toggleMore(true)">Apply</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div ref="views" class="agenda__views" :class="{ animate: highlightViews }">
				<div>
					<a :class="{ 'is-active': view === 'calendar' }" @click.prevent="switchView('calendar')"
						><span class="icon"><inline-svg :src="require('../assets/calendar.svg')" width="32"></inline-svg></span
					></a>
					<a :class="{ 'is-active': view === 'timeline' }" @click.prevent="switchView('timeline')"
						><span class="icon"><inline-svg :src="require('../assets/timeline.svg')" width="32"></inline-svg></span
					></a>
					<a :class="{ 'is-active': view === 'list' }" @click.prevent="switchView('list')"
						><span class="icon"><inline-svg :src="require('../assets/bulleted-list.svg')" width="32"></inline-svg></span
					></a>
				</div>
				<span>Programme View</span>
			</div>
		</div>

		<template v-if="filteredSessionList && filteredSessionList.length > 0">
			<template v-if="view === 'list'">
				<div v-for="session in filteredSessionList" :key="session.id" class="agenda__session">
					<div class="agenda__session__start">
						<span>{{ session.start | timeFormat(session.date) }}</span>
					</div>
					<AgendaSessionBox :session="session" view="large" :alt-title="altTitle" :starred="starred" />
				</div>
			</template>
			<AgendaTimeline v-if="view === 'timeline'" :current-date="currentDate" :sessions="filteredSessionList" :alt-title="altTitle" :starred="starred" />
			<AgendaCalendar v-if="view === 'calendar'" :current-date="currentDate" :sessions="filteredSessionList" :alt-title="altTitle" :starred="starred" />
		</template>
		<template v-if="filteredSessionList && filteredSessionList.length === 0">
			<div class="agenda__status is-full is-processing">
				<span class="icon"><inline-svg :src="require('../assets/info.svg')" width="25"></inline-svg></span>
				<span v-if="!onlyFavourites">No sessions found with the current filter.</span>
				<span v-else
					>There are currently no sessions added to your personal agenda. You can add these by selecting specific sessions that interest you from the
					<router-link :to="{ name: 'Agenda' }">main programme agenda</router-link>.</span
				>
			</div>
		</template>
	</div>
</template>

<script>
	import $http from '../utils/http.js';
	import moment from 'moment-timezone';
	import AgendaCalendar from '../components/AgendaCalendar';
	import AgendaTimeline from '../components/AgendaTimeline';
	import AgendaSessionBox from '../components/AgendaSessionBox';
	import { intersectSupported } from '../utils/utils';
	import { EVENT_NAME } from '../consts';

	const debounce = (fn, delay) => {
		var timeoutID = null;
		return function() {
			clearTimeout(timeoutID);
			var args = arguments;
			var that = this;
			timeoutID = setTimeout(function() {
				fn.apply(that, args);
			}, delay);
		};
	};

	export default {
		name: 'AgendaContent',
		components: { AgendaSessionBox, AgendaTimeline, AgendaCalendar },
		props: {
			name: {
				type: String,
				required: true,
			},
			sessions: {
				type: Array,
				required: true,
			},
			categories: {
				type: Array,
				required: true,
			},
			altTitle: {
				type: Boolean,
				default: false,
			},
			starred: {
				type: Boolean,
				default: true,
			},
			defaultView: {
				type: String,
				default: 'calendar',
			},
			defaultCategory: {
				type: Number,
				default: 0,
			},
			showCategories: {
				type: Boolean,
				default: true,
			},
			showFilter: {
				type: Boolean,
				default: true,
			},
			allCategories: {
				type: Boolean,
				default: false,
			},
			onlyFavourites: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				programme: {},
				dates: [],
				currentDate: null,
				currentList: false,
				search: '',
				debouncedSearch: '',
				view: 'calendar',
				selectedCategory: 0,
				searchMore: false,
				filterType: [],
				filterSpeaker: [],
				filterChannel: [],
				filterHall: [],
				filterBreaks: false,
				filterFavourites: false,
				observer: null,
				highlightViews: false,
			};
		},
		computed: {
			types() {
				let types = [];
				for (let day in this.programme) {
					for (let session of this.programme[day]) {
						if (session.session_type) {
							if (!types.find(e => e.id === session.session_type.id)) {
								types.push(session.session_type);
							}
						}
					}
				}
				return types.sort((a, b) => a.name.localeCompare(b.name));
			},
			speakers() {
				let speakers = [];
				for (let day in this.programme) {
					for (let session of this.programme[day]) {
						if (session.speakers) {
							for (let speaker of session.speakers) {
								if (!speakers.find(e => e.id === speaker.id)) {
									speakers.push(speaker);
								}
							}
						}
					}
				}
				return speakers.sort((a, b) => a.name.localeCompare(b.name));
			},
			channels() {
				let channels = [];
				for (let day in this.programme) {
					for (let session of this.programme[day]) {
						if (session.live_track) {
							if (!channels.find(e => e.id === session.live_track.id)) {
								channels.push(session.live_track);
							}
						}
					}
				}
				return channels.sort((a, b) => {
					if (a.title && b.title) return a.title.localeCompare(b.title);
					return 0;
				});
			},
			halls() {
				let halls = [];
				for (let day in this.programme) {
					for (let session of this.programme[day]) {
						if (session.session_hall) {
							if (!halls.find(e => e.id === session.session_hall.id)) {
								halls.push(session.session_hall);
							}
						}
					}
				}
				return halls.sort((a, b) => a.name.localeCompare(b.name));
			},
			searchFilterActive() {
				return (
					(this.filterSpeaker && this.filterSpeaker.length > 0) ||
					(this.filterChannel && this.filterChannel.length > 0) ||
					(this.filterHall && this.filterHall.length > 0) ||
					this.filterBreaks !== false
				);
			},
			searchFilterCount() {
				let c = 0;
				if (this.filterSpeaker && this.filterSpeaker.length > 0) c++;
				if (this.filterChannel && this.filterChannel.length > 0) c++;
				if (this.filterHall && this.filterHall.length > 0) c++;
				if (this.filterBreaks !== false) c++;
				return c;
			},
			sessionList() {
				return this.programme[this.currentDate];
			},
			filteredSessionList() {
				if (!this.sessionList) return false;
				return this.sessionList.filter(e => {
					let retVal = false;

					if (!this.allCategories) {
						if (e.session_category?.id === this.selectedCategory) retVal = true;
					} else {
						retVal = true;
					}

					if (retVal === true && this.search.length > 1) {
						let searchHit = false;
						if (searchHit === false && e.title && e.title.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) searchHit = true;
						if (searchHit === false && e.searchType && e.searchType.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) searchHit = true;
						if (searchHit === false && e.searchSpeakers && e.searchSpeakers.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) searchHit = true;
						if (searchHit === false) retVal = false;
					}

					if (retVal === true && this.filterType?.length > 0 && e.session_type?.id) {
						//retVal = this.filterType.indexOf(e.session_type.id) !== -1;

						retVal = this.filterType.some(d => d.id === e.session_type.id);
					}

					if (retVal === true && this.filterChannel?.length > 0 && e.live_track?.id) {
						retVal = this.filterChannel.some(d => d.id === e.live_track.id);
					}

					if (retVal === true && this.filterSpeaker?.length > 0) {
						if (e.speakers.length > 0) {
							retVal = this.filterSpeaker.some(d => e.speakers.find(e => e.id === d.id));
						} else {
							retVal = false;
						}
					}

					if (retVal === true && this.filterHall?.length > 0) {
						if (e.session_hall) {
							retVal = this.filterHall.some(d => d.id === e.session_hall.id);
						} else {
							retVal = false;
						}
					}

					if (retVal === true && this.filterFavourites === true) {
						retVal = e.favourite === true;
					}

					if (retVal === true && this.filterBreaks === true) {
						retVal = e.visitable === true;
					}

					if (retVal === true && this.onlyFavourites === true) {
						retVal = e.favourite === true;
					}

					return retVal;
				});
			},
		},
		watch: {
			debouncedSearch: debounce(function(newVal) {
				this.search = newVal;
				this.saveFilter();
			}, 500),
			filterType: 'saveFilter',
		},
		mounted() {
			this.view = this.defaultView;
			this.selectedCategory = this.defaultCategory;

			if (this.selectedCategory === 0 && this.categories.length > 0) {
				this.selectedCategory = this.categories[0].id;
			}

			let sessions = this.sessions.reduce(function(r, a) {
				r[a.date] = r[a.date] || [];
				r[a.date].push(a);
				return r;
			}, Object.create(null));

			this.dates = Object.keys(sessions).sort((a, b) => (new Date(a) > new Date(b) ? 1 : new Date(a) < new Date(b) ? -1 : 0));
			this.currentDate = this.dates.length > 0 ? this.dates[0] : null;

			let dateSwitch = window.location.hash;
			if (dateSwitch) {
				let day = dateSwitch.split('-');
				if (day && day[1] && parseInt(day[1])) {
					day = parseInt(day[1]) - 1;
					this.currentDate = this.dates.length >= day ? this.dates[day] : this.dates[0];
				}
			} else {
				if (this.dates.length > 0) {
					let now = moment().tz('CET');

					let index = this.dates.findIndex(e => {
						return now.isSame(moment(e, 'YYYY-MM-DD', 'CET'), 'day');
					});
					if (index > 0) {
						this.currentDate = this.dates[index];
					}
				}
			}

			Object.keys(sessions).map(key => {
				sessions[key].sort((a, b) => a.live_track && b.live_track && (a.live_track.order > b.live_track.order ? 1 : a.live_track.order < b.live_track.order ? -1 : 0));
				sessions[key].sort((a, b) => (a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0));
				sessions[key].sort((a, b) =>
					moment(`${a.date} ${a.start}`).toDate() > moment(`${b.date} ${b.start}`).toDate()
						? 1
						: moment(`${a.date} ${a.start}`).toDate() < moment(`${b.date} ${b.start}`).toDate()
						? -1
						: 0
				);

				sessions[key].map(e => {
					if (e.session_type && e.session_type.name) {
						e.searchType = e.session_type.name;
					}

					if (e.speakers && e.speakers.length > 0) {
						e.searchSpeakers = '';
						e.speakers = e.speakers.sort((a, b) => (a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0));
						e.speakers.map(s => {
							e.searchSpeakers += s.name;
							return s;
						});
					}
					return e;
				});
			});

			this.programme = sessions;
			this.loadFilter();

			let storedView = window.localStorage.getItem(`${EVENT_NAME}-agendaView` + this.name);
			if (storedView) {
				this.view = storedView;
			}

			let pvHighlight = window.localStorage.getItem(`${EVENT_NAME}-pvhighlight`);
			if (pvHighlight !== 'true') {
				if (intersectSupported()) {
					this.observer = new IntersectionObserver(
						entries => {
							if (entries[0].isIntersecting) {
								this.highlightViews = true;
								this.observer.unobserve(this.$refs.views);
								window.localStorage.setItem(`${EVENT_NAME}-pvhighlight`, 'true');
							}
						},
						{ threshold: 1 }
					);

					this.observer.observe(this.$refs.views);
				}
			}
		},
		methods: {
			selectDate(date) {
				this.currentDate = date;
				this.saveFilter();
			},
			toggleFavourite(session) {
				session.favourite = !session.favourite;
				$http.put(`/event-programme-favourite/${session.id}`).then(
					() => {},
					() => {
						session.favourite = !session.favourite;
					}
				);
			},
			selectCategory(id) {
				this.selectedCategory = id;
				this.saveFilter();
			},
			isLive({ date, start, end }) {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${date} ${start}`, 'CET');
				let dEnd = moment.tz(`${date} ${end}`, 'CET');
				let now = moment().tz(target);

				return now.isBetween(dStart, dEnd);
			},
			isLiveExternal({ date, start, end }) {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${date} ${start}`, 'Europe/Lisbon').subtract(15, 'minutes');
				let dEnd = moment.tz(`${date} ${end}`, 'Europe/Lisbon');
				let now = moment().tz(target);

				return now.isBetween(dStart, dEnd);
			},
			closeSearchFilters() {
				this.searchMore = false;
				this.saveFilter();
			},
			toggleMore() {
				this.searchMore = !this.searchMore;
				this.saveFilter();
			},
			clearFilters() {
				this.searchMore = !this.searchMore;
				this.filterSpeaker = [];
				this.filterChannel = [];
				this.filterHall = [];

				this.saveFilter();
			},
			switchView(view) {
				window.localStorage.setItem(`${EVENT_NAME}-agendaView` + this.name, view);
				this.view = view;
				this.$gtm.dataLayer().push({
					event: 'gaEvent',
					eCategory: 'No Booth Event',
					eAction: 'Agenda - Programme View',
					eLabel: view,
					Exhibitor: '(not set)',
					Booth: '(not set)',
				});
			},
			saveFilter() {
				let obj = {
					currentDate: this.currentDate,
					selectedCategory: this.selectedCategory,
					search: this.search,
					filterType: this.filterType,
					filterSpeaker: this.filterSpeaker,
					filterChannel: this.filterChannel,
					filterHall: this.filterHall,
					filterBreaks: this.filterBreaks,
					filterFavourites: this.filterFavourites,
				};
				window.sessionStorage.setItem('agendaFilter' + this.name, JSON.stringify(obj));
			},
			loadFilter() {
				this.$nextTick(() => {
					if (this.$route.meta.fromHistory) {
						let obj = window.sessionStorage.getItem('agendaFilter' + this.name);
						if (obj) {
							try {
								obj = JSON.parse(obj);
								if (obj.currentDate) {
									this.selectDate(obj.currentDate);
								}
								if (this.showCategories) {
									this.selectedCategory = obj.selectedCategory ? obj.selectedCategory : this.categories[0].id;
								}
								this.debouncedSearch = obj.search;
								this.search = obj.search;
								this.filterType = obj.filterType;
								this.filterSpeaker = obj.filterSpeaker;
								this.filterChannel = obj.filterChannel;
								this.filterHall = obj.filterHall;
								this.filterBreaks = obj.filterBreaks;
								this.filterFavourites = obj.filterFavourites;
							} catch (err) {
								console.log(err);
								// pass
							}
						}
					}
				});
			},
		},
	};
</script>
